<template>
  <div class="contact">
    <pageHeader> </pageHeader>

    <div class="contact-content sec-padding">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <div class="contact-head">
              <h2 class="font-bold main-title main-color">اترك رسالتك</h2>
              <p class="d-flex align-center">
                <v-icon color="#a53860" small>mdi-checkbox-blank-circle</v-icon>
                <span class="line"></span>
              </p>
            </div>
            <div class="content">
              <p class="text-center error--text mb-8">
                {{ validation_errors }}
              </p>
              <v-form v-model="valid" ref="form" class="my-4">
                <v-row>
                  <v-col class="py-0">
                    <v-text-field
                      v-model="contactInfo.name"
                      label="الإسم * "
                      name="name"
                      :rules="[
                        validationRules.required,
                        validationRules.spaces,
                      ]"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0">
                    <v-text-field
                      v-model="contactInfo.email"
                      label="البريد الالكترونى"
                      name="email"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-text-field
                      v-model="contactInfo.mobile"
                      label="رقم الجوال * "
                      name="phone"
                      :rules="[
                        validationRules.required,
                        validationRules.spaces,
                        validationRules.phoneNum,
                      ]"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0">
                    <v-select
                      v-model="contactInfo.subject"
                      :items="msgSubjects"
                      label="عنوان الرسالة * "
                      name="subject"
                      :rules="[validationRules.required]"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-textarea
                      v-model="contactInfo.message"
                      label="الرسالة * "
                      name="message"
                      :rules="[
                        validationRules.required,
                        validationRules.spaces,
                      ]"
                      solo
                    ></v-textarea>
                    <v-btn
                      color="#a53860"
                      class="white--text font-bold"
                      large
                      @click.prevent="submitData"
                      >ارسال</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pr-sm-12">
            <div class="contact-head map">
              <h2 class="font-bold main-title main-color">تواصل معنا</h2>
              <p class="d-flex align-center">
                <v-icon color="#a53860" small>mdi-checkbox-blank-circle</v-icon>
                <span class="line"></span>
              </p>
            </div>
            <div class="iframMap mb-8" v-html="mapIfram"></div>
            <div class="content">
              <h3 class="dark-gray-text mb-4">{{ country }}</h3>
              <p class="gray-text location">
                <v-icon color="#46101f">mdi-map-marker</v-icon>

                {{ address }}
              </p>
              <p class="gray-text whatsapp" v-if="whatsapp">
                <i class="fab fa-whatsapp"></i>
                <span class="d-inline-block px-2 ltr">
                  <a :href="`https://wa.me/${whatsapp}`" target="_blank">{{
                    whatsapp
                  }}</a>
                </span>
              </p>
              <p class="gray-text phone" v-if="phone">
                <v-icon color="#46101f">mdi-phone</v-icon>
                <span class="d-inline-block px-2 ltr">
                  <a :href="`tel:${phone}`">
                    {{ phone }}
                  </a>
                </span>
              </p>

              <p class="gray-text email" v-if="email">
                <v-icon color="#46101f">mdi-email</v-icon>
                <span class="d-inline-block px-2">
                  <a :href="`mailto:${email}`">
                    {{ email }}
                  </a>
                </span>
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-dialog v-model="dialog" width="50%">
      <v-card id="contact-dialog">
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
        <v-card-text class="text-center pa-8 pa-sm-16">
          <h2 class="main-title main-color font-bold mb-5">شكرا</h2>
          <p class="second-color message-send">تم ارسال رسالتك بنجاح</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "../mixins/validationMixin";

import pageHeader from "@/components/pageHeader.vue";
export default {
  name: "contact",
  mixins: [validationMixin],

  components: { pageHeader },
  data() {
    return {
      valid: false,
      dialog: false,
      msgSubjects: ["استفسار", "اقتراحات", "شكوى", "اخرى"],
      contactInfo: {
        name: "",
        email: "",
        mobile: "",
        subject: "",
        message: "",
      },
      validation_errors: "",
      phone: "",
      whatsapp: "",
      email: "",
      address: "",
      country: "",
      mapIfram: "",
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    getHomeData() {
      this.$http
        .get(this.base_url + "/front/getDataHome", {})
        .then((response) => {
          this.phone = response.data.data.phone;
          this.whatsapp = response.data.data.whatsApp;
          this.email = response.data.data.email;
          this.address = response.data.data.address;
          this.country = response.data.data.country;
          this.mapIfram = response.data.data.contact_us_map_html;
        });
    },
    submitData() {
      if (this.valid) {
        this.$http
          .post(this.base_url + "/front/contact", this.contactInfo)
          .then((response) => {
            if (response.data.status.error) {
              this.validation_errors = response.data.status.message;
            } else {
              this.dialog = true;
              this.contactInfo = {
                name: "",
                email: "",
                mobile: "",
                subject: "",
                message: "",
              };
              this.$refs.form.resetValidation();
            }
          });
      } else {
        this.validate();
      }
    },
  },
  mounted() {
    this.getHomeData();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";

.ltr {
  direction: ltr !important;
}
.contact {
  .contact-content {
    .contact-head {
      margin-bottom: 50px;
      .line {
        display: inline-block;
        width: 55px;
        height: 1px;
        background: $second-color;
        margin-right: -3px !important;
      }
    }
    .map.contact-head {
      margin-bottom: 40px;
    }
  }

  svg {
    font-size: 20px;
    color: $main-color;
  }
}

.phone .v-icon {
  transform: scaleX(-1);
}
#contact-dialog {
  border-radius: 20px !important;
}
#contact-dialog .v-btn {
  margin: 20px !important;
  color: #b9b9b9 !important;
  font-size: 25px !important;
}
.message-send {
  font-size: 1.5rem !important;
}
</style>
