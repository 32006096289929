<template>
  <div>
    <sideMenu ref="sideMenu" :drawer="drawer" />
    <div class="header">
      <v-container fluid>
        <v-row class="main-head">
          <v-col>
            <div class="d-flex d-sm-block justify-space-between">
              <router-link to="/">
                <v-img
                  src="../assets/logo.png"
                  class="logo"
                  width="200px"
                ></v-img>
              </router-link>
              <div class="nav-actions">
                <img
                  src="@/assets/menu.png"
                  @click="openSideMenu"
                  alt=""
                  class="menu-img"
                  width="38px"
                />
              </div>
            </div>
          </v-col>
          <v-col class="text-left d-none d-sm-block">
            <v-badge
              color="#a53860"
              overlap
              class="notification-badge"
              v-if="notificationExist"
            >
              <v-btn icon to="/salonRequests">
                <v-icon color="#ffffff">mdi-bell</v-icon>
              </v-btn>
            </v-badge>

            <v-btn v-else icon to="/salonRequests">
              <v-icon color="#ffffff">mdi-bell</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="goToSearchPage()"
              large
              color="#ffffff"
              v-if="showSearch"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              class="white--text main-btn login-btn mx-4"
              color="#a53860"
              to="/login"
              v-if="loggedIn != 'true'"
              >تسجيل الدخول</v-btn
            >
            <v-btn v-if="loggedIn" to="/profile" class="mx-4 account-btn" icon>
              <v-icon v-if="!profileImage" color="#ffffff" large
                >mdi-account</v-icon
              >

              <img
                v-else
                class="profile_picture"
                :src="profileImage"
                alt="profile picture"
              />
            </v-btn>
            <slot v-if="$route.query.step != 2"
              ><cartMenu ref="cartMenu"
            /></slot>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <notificationPopup
      :snackbar="snackbar"
      @closeSnack="closeSnack"
      color="#ffffff"
      head=""
      text="لديك طلبات حجز جديدة"
    />
  </div>
</template>

<script>
import sideMenu from "@/components/sideMenu.vue";

import cartMenu from "@/components/cartMenu.vue";
import notificationPopup from "@/components/notificationPopup.vue";
import AUTH from "../auth.js";

export default {
  name: "pageHeader",
  components: { cartMenu, sideMenu, notificationPopup },
  data() {
    return {
      notificationExist: false,
      snackbar: false,

      drawer: false,
      profileImage: localStorage.profile_image,

      loggedIn: localStorage.loggedIn,
      showSearch: this.$route.path !== "/search" ? true : false,
    };
  },
  methods: {
    closeSnack() {
      this.snackbar = false;
    },
    openSideMenu() {
      this.$refs.sideMenu.toggleDrawer();
    },
    goToSearchPage() {
      this.$router.push("/search?search=");
    },
  },
  mounted() {
    if (window.location.protocol == "https:") {
      this.$socket
        .channel("NotificationReceived")
        .listen("NotificationReceived", (e) => {
          // console.log(parseInt(e.user_id) == parseInt(AUTH.getID("salon")));
          if (parseInt(e.user_id) == parseInt(AUTH.getID())) {
            // this.notifications.unshift(e.notification);
            this.notificationExist = true;
            this.snackbar = true;
          }
        });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";

.header {
  background-image: url("../assets/bg.jpg");
  border-radius: 0 0 0 40px;
  background-size: cover;
  height: 300px !important;
  position: relative;
  padding-right: 50px;
  padding-left: 50px;
  .nav-actions {
    margin-top: 6rem;
    .menu-img {
      transform: scaleX(-1);
      cursor: pointer !important;
    }
  }
  .main-head {
    margin-top: 2rem !important;
  }
  .search-sec {
    max-width: 80%;
    text-align: center;
    margin: 0 auto !important;
    margin-top: 4rem !important;
  }
}

@media (max-width: 600px) {
  .header {
    padding-right: 12px;
    padding-left: 12px;
    .logo {
      width: 150px !important;
    }
    .nav-actions {
      margin-top: 0;

      .menu-img {
        width: 32px;
        transform: scaleX(-1);
        cursor: pointer !important;
      }
    }
  }
}
</style>
